<template>
  <div class="relative w-full h-full">
    <OfferCarouselSlider :offers="homeBanner" :preload="true" class="mt-8 mx-auto" />

    <section class="mt-14 lg:mt-28 container mx-auto relative text-center [ px-6 lg:px-0 ]">
      <MidPageBanner />
    </section>

    <DelayHydration>
      <section class="mt-14 lg:mt-28 container mx-auto relative text-center [ px-6 lg:px-0 ]">
        <LazyHomePageGraph />
      </section>
    </DelayHydration>

    <DelayHydration>
      <OfferCarouselSlider v-if="items.length" :offers="items" :lazy="true" class="mt-8 mx-auto" />
    </DelayHydration>

    <DelayHydration v-if="latestProducts.length">
      <section class="mt-14 md:mt-25 relative text-center">
        <MainTitle class="[ px-6 lg:px-0 ]">
          <template #before>
            {{ $t('ourLatest') }}
          </template>
          {{ $t('offers') }}
        </MainTitle>
        <client-only>
          <LazyProductSlider
            id="latest-products"
            v-slot="{ item }"
            class="mt-12"
            :items-per-row-on-mobile="1.8"
            :items="latestProducts"
          >
            <ProductCard :product="item" name-only />
          </LazyProductSlider>
        </client-only>
      </section>
    </DelayHydration>

    <DelayHydration v-if="topDeals.length">
      <section class="mt-14 md:mt-25 relative text-center">
        <MainTitle class="[ px-6 lg:px-0 ]">
          <template #before>
            {{ $t('topDeals') }}
          </template>
        </MainTitle>
        <client-only>
          <LazyProductSlider
            id="top-deals"
            v-slot="{ item }"
            class="mt-12"
            :items="topDeals"
            :items-per-row-on-mobile="1.8"
          >
            <ProductCard :product="item" name-only />
          </LazyProductSlider>
        </client-only>
      </section>
    </DelayHydration>

    <DelayHydration>
      <section class="mt-14 md:mt-25 container mx-auto relative text-center [ px-6 lg:px-0 ]">
        <LazyOwnerSupport />
      </section>

      <section class="mt-28 container mx-auto relative text-center [ pl-6 lg:pl-0 ]">
        <LazyCommercialProducts />
      </section>
    </DelayHydration>
  </div>
</template>

<script setup lang="ts">
import { useHomeItems } from '~/composables/home';

import type { OfferCardFragment } from '~/graphql/fragments';
const { formatPageTitle } = useSeoFormatting();
const { t: $t } = useI18n({
  useScope: 'local',
});

useAppSeoMeta({
  title: formatPageTitle($t('home').toString()),
});

const { topDeals, latestProducts, mappedHomeOffers } = await useHomeItems();
const items = computed(() =>
  [
    mappedHomeOffers?.[6],
    mappedHomeOffers?.[7],
    mappedHomeOffers?.[8],
    mappedHomeOffers?.[9],
    mappedHomeOffers?.[10],
    mappedHomeOffers?.[11],
  ]?.filter((item): item is OfferCardFragment => !!item),
);

const homeBanner = computed(() =>
  [
    mappedHomeOffers?.[0],
    mappedHomeOffers?.[1],
    mappedHomeOffers?.[2],
    mappedHomeOffers?.[3],
    mappedHomeOffers?.[4],
    mappedHomeOffers?.[5],
  ]?.filter((item): item is OfferCardFragment => !!item),
);
</script>

<style lang="postcss" scoped>
.Offers {
  :deep(.OfferCard) {
    &:first-child {
      @apply col-span-2;

      @screen lg {
        @apply row-span-2;
      }
    }
  }
}

.Categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 162px;
  column-gap: 10px;
  row-gap: 10px;

  @screen lg {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 285px;
    column-gap: 20px;
    row-gap: 20px;
  }

  > *:last-child:nth-child(even) {
    @apply col-span-2;
  }
}

.SecondaryOffers {
  &__Grid {
    @apply px-5 lg:px-0 grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-5 pt-16;
    grid-auto-rows: 180px;

    @screen lg {
      grid-auto-rows: 333px;
    }

    :deep(.OfferCard) {
      &:first-child {
        @screen lg {
          @apply row-span-2;
        }
      }
    }
  }

  &__bg {
    @apply bg-primary-100;
    height: 500px;
  }
}

.background-layer {
  height: 850px;
  margin-top: -500px;
  margin-bottom: -375px;

  @screen md {
    height: 546px;
    margin-top: -521px;
    margin-bottom: 0;
  }
}

.large-container {
  @apply mx-auto;
  max-width: 2800px;
}
</style>

<i18n>
{
  "en": {
    "scrollDown": "Scroll Down",
    "shopByCategory": "Shop By Category",
    "sale": "Sale",
    "saleDesc": "Don’t miss our monthly deals on selective products",
    "latest": "Latest Products",
    "bestSellers": "Best Sellers",
    "topBrands": "Top Brands",
    "shopBy": "Shop By",
    "category": "Category",
    "ourLatest": "Our Latest",
    "offers": "Offers",
    "topDeals": "Top Products",
    "dontMissDeals": "Don’t Miss Our Hot Deals",
    "featuredDeals": "Featured Deals",
    "brands": "Featured Brands",
    "home": "Home"
  },
  "ar": {
    "scrollDown": "",
    "shopByCategory": "تسوق بالقسم",
    "sale": "تخفيضات",
    "saleDesc": "لا يفوتك عروضنا الشهرية",
    "latest": "آخر المنتجات",
    "bestSellers": "الأكثر مبيعا",
    "topBrands": "اشهر الماركات",
    "shopBy": "تسوق بواسطة",
    "category": "القسم",
    "ourLatest": "آخر",
    "offers": "عروضنا",
    "topDeals": "أفضل المنتجات",
    "dontMissDeals": "لا تفوت عروضنا الساخنة",
    "featuredDeals": "عروض مميزة",
    "brands": "الماركات المميزة",
    "home": "الرئيسية"
  }
}
</i18n>
